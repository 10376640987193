import * as Dialog from '@radix-ui/react-dialog';
import classnames from 'classnames';
import * as React from 'react';

import { useOverflow } from '../../hooks/useOverflow';
import { theme } from '../../styles/theme.css';
import { Box, BoxProps } from '../Box';
import { IconButton } from '../IconButton';
import { Loader } from '../Loader';
import {
  SideNavigationMenu,
  SideNavigationMenuProps,
} from '../SideNavigationMenu';
import { Tabs, TabsProps } from '../Tab';
import { Typography, TypographyProps } from '../Typography';

import { modalStyles } from './Modal.css';

export type ModalProps = Dialog.DialogProps;

export enum ModalWidth {
  SMALL = 501,
  BASIC = 604,
  LARGE = 707,
  EXTRA_LARGE = 970,
}

export type ModalContentProps = {
  children: React.ReactNode;
  closeRef?: React.RefObject<HTMLButtonElement>;
  contentClassName?: string;
  header?: React.ReactNode;
  height?: string | number;
  modalStyle?: React.CSSProperties;
  title?: React.ReactNode;
  width?: ModalWidth;
} & Omit<BoxProps, 'title'> &
  Omit<Dialog.DialogContentProps, 'title'>;

const ModalContentWithPortal = React.forwardRef<
  HTMLDivElement,
  ModalContentProps
>((props, ref) => {
  return (
    <Dialog.Portal>
      <ModalContentWithOverlay {...props} ref={ref} />
    </Dialog.Portal>
  );
});

ModalContentWithPortal.displayName = 'ModalContentWithPortal';

const ModalContentWithOverlay = React.forwardRef<
  HTMLDivElement,
  ModalContentProps
>(
  (
    {
      children,
      closeRef,
      header,
      title,
      width = ModalWidth.BASIC,
      modalStyle,
      height,
      forceMount,
      contentClassName,
      onOpenAutoFocus,
      onCloseAutoFocus,
      onEscapeKeyDown,
      onPointerDownOutside,
      onInteractOutside,
      ...props
    },
    ref
  ) => {
    return (
      // we wrap the content with the overlay as a workaround to a scroll issue https://github.com/radix-ui/primitives/issues/1159
      <Dialog.Overlay className={modalStyles.overlay}>
        <Dialog.Content
          ref={ref}
          forceMount={forceMount}
          className={classnames(modalStyles.content, contentClassName)}
          onOpenAutoFocus={onOpenAutoFocus}
          onCloseAutoFocus={onCloseAutoFocus}
          onEscapeKeyDown={onEscapeKeyDown}
          onPointerDownOutside={onPointerDownOutside}
          onInteractOutside={onInteractOutside}
          style={{ width: width, maxWidth: width, height, ...modalStyle }}
        >
          {header ? (
            header
          ) : title ? (
            <Box
              justifyContent="space-between"
              alignItems="center"
              padding="s6"
              gap="s5"
              className={modalStyles.header}
            >
              <Box as="header" flexDirection="column" gap="s2">
                <ModalTitle>{title}</ModalTitle>
              </Box>
              <Dialog.Close asChild ref={closeRef}>
                <IconButton
                  className={modalStyles.closeButton}
                  icon="close"
                  variant="secondary"
                  size="small"
                />
              </Dialog.Close>
            </Box>
          ) : null}
          <Box
            as="main"
            flexDirection="column"
            overflow="hidden"
            flexGrow={1}
            flexShrink={1}
            {...props}
          >
            {children}
          </Box>
        </Dialog.Content>
      </Dialog.Overlay>
    );
  }
);

ModalContentWithOverlay.displayName = 'ModalContentWithOverlay';

export type ModalContentWithSideNavigationProps<T extends string> = {
  title: string;
  footer?: React.ReactNode;
  isLoading?: boolean;
} & SideNavigationMenuProps<T>;

// Because forwardRef is tricky with generics, we declare the component first
// and then cast the result of forwardRef
const ModalContentWithSideNavigationBase = <T extends string>(
  {
    title,
    isLoading,
    activeItem,
    items,
    onClickItem,
    footer,
  }: ModalContentWithSideNavigationProps<T>,
  ref: React.ForwardedRef<HTMLDivElement>
) => {
  const sectionRef = React.useRef<HTMLElement>(null);
  const { overflowsY } = useOverflow({ ref: sectionRef });

  return (
    <Modal.RawContentWithOverlay
      ref={ref}
      width={ModalWidth.LARGE}
      title={title}
    >
      <Box flex={1} overflow="hidden" flexDirection="column">
        <Box
          paddingLeft="s6"
          paddingTop="s6"
          paddingBottom={overflowsY ? undefined : 's4'}
          gap="s4"
          overflowY="hidden"
        >
          <SideNavigationMenu
            items={items}
            activeItem={activeItem}
            onClickItem={onClickItem}
          />
          <Box
            as="section"
            ref={sectionRef}
            style={{ width: '100%' }}
            overflowY="auto"
            overflowX="hidden"
            paddingLeft="s4"
            paddingRight="s6"
          >
            {isLoading ? (
              <Box flex={1} justifyContent="center" alignItems="center">
                <Loader style={{ width: 40, height: 40 }} />
              </Box>
            ) : (
              <Box flexDirection="column" flex={1}>
                {items.map((item) => (
                  <Box
                    key={item.key}
                    className={
                      activeItem?.key === item.key
                        ? undefined
                        : modalStyles.hiddenContent
                    }
                  >
                    {item.screen}
                  </Box>
                ))}
                <Box
                  style={{
                    height: overflowsY ? 24 : 0,
                    width: '100%',
                    flexShrink: 0,
                  }}
                />
              </Box>
            )}
          </Box>
        </Box>
        {footer ? (
          <Box
            as="footer"
            justifyContent="flex-end"
            style={{
              borderTop: overflowsY
                ? `1px solid ${theme.color.border.secondary}`
                : undefined,
            }}
            paddingTop="s6"
            paddingRight="s6"
            paddingBottom="s6"
          >
            {footer}
          </Box>
        ) : null}
      </Box>
    </Modal.RawContentWithOverlay>
  );
};

const ModalContentWithSideNavigation = React.forwardRef(
  ModalContentWithSideNavigationBase
) as <T extends string>(
  props: ModalContentWithSideNavigationProps<T> & {
    ref?: React.ForwardedRef<HTMLDivElement>;
  }
) => ReturnType<typeof ModalContentWithSideNavigationBase>;

export type ModalContentScrollableProps = ModalContentProps & {
  footer?: React.ReactNode;
};

const ModalContentScrollable = React.forwardRef<
  HTMLDivElement,
  ModalContentScrollableProps
>(
  (
    { children, title, header, footer, width, height, closeRef, ...props },
    ref
  ) => {
    const sectionRef = React.useRef<HTMLElement>(null);
    const { overflowsY } = useOverflow({ ref: sectionRef });

    return (
      <Modal.RawContentWithOverlay
        ref={ref}
        title={title}
        header={header}
        width={width}
        height={height}
        closeRef={closeRef}
      >
        <Box
          as="section"
          ref={sectionRef}
          flexDirection="column"
          overflowY="auto"
          overflowX="hidden"
          paddingTop="s6"
          paddingHorizontal="s6"
          paddingBottom="s6"
          flexGrow={1}
          {...props}
        >
          {children}
        </Box>
        {footer ? (
          <Box
            as="footer"
            justifyContent="flex-end"
            style={{
              borderTop: overflowsY
                ? `1px solid ${theme.color.border.secondary}`
                : undefined,
            }}
            padding="s6"
          >
            {footer}
          </Box>
        ) : null}
      </Modal.RawContentWithOverlay>
    );
  }
);

ModalContentScrollable.displayName = 'ModalContentScrollable';

const ModalTitle = ({
  children,
  ...props
}: React.PropsWithChildren<TypographyProps<'span'>>) => {
  return (
    <Dialog.Title asChild>
      <Typography
        as="h2"
        variant="headingMedium"
        color="primary"
        showTooltipWhenOverflowing
        lineClamp={1}
        {...props}
      >
        {children}
      </Typography>
    </Dialog.Title>
  );
};

type ModalTabsHeaderProps<T> = Pick<
  TabsProps<T>,
  'active' | 'onTabChange' | 'options' | 'defaultValue'
> & {
  title: React.ReactNode;
};

const ModalTabsHeader = <T extends string>({
  title,
  active,
  defaultValue,
  onTabChange,
  options,
}: ModalTabsHeaderProps<T>) => {
  return (
    <Box as="header" flexDirection="column" paddingTop="s6" gap="s6">
      <Box
        paddingHorizontal="s6"
        justifyContent="space-between"
        alignItems="center"
        gap="s6"
      >
        <Modal.Title paddingBottom="s1">{title}</Modal.Title>
        <Modal.Close asChild>
          <IconButton icon="close" variant="secondary" size="small" />
        </Modal.Close>
      </Box>
      <Tabs
        options={options}
        defaultValue={defaultValue}
        onTabChange={onTabChange}
        active={active}
        variant="underlined"
        size="small"
        tabsContainerProps={{
          style: {
            paddingLeft: theme.spacing.s6,
          },
        }}
      />
    </Box>
  );
};

export const Modal = {
  Root: Dialog.Root,
  Portal: Dialog.Portal,
  Trigger: Dialog.Trigger,
  ContentWithPortal: ModalContentWithPortal,
  RawContentWithOverlay: ModalContentWithOverlay,
  ContentWithSideNavigation: ModalContentWithSideNavigation,
  ContentScrollable: ModalContentScrollable,
  Close: Dialog.Close,
  Title: ModalTitle,
  TabsHeader: ModalTabsHeader,
};
