import * as React from 'react';

import { cssVariable } from '../../utils/styles';
import { Box, BoxProps } from '../Box';
import { Button } from '../Button';
import { Typography } from '../Typography';

import {
  stepButtonStyles,
  stepCompletionPercentage,
  StepperVariant,
} from './StepButton.css';

export type StepperStepComponentProps = {
  currentStepIndex: number;
  currentSubStepIndex: number;
  navigateTo: NavigateTo;
  navigateToSubStep: NavigateToSubStep;
};

export type StepperStepComponent = ({
  navigateTo,
  currentStepIndex,
}: StepperStepComponentProps) => React.ReactNode;

export type StepperStep = {
  Render: StepperStepComponent;
  id: string;
  title: string;
  disabled?: never;
  steps?: never;
};

export type StepperStepWithSubSteps = {
  id: string;
  title: string;
  Render?: never;
  disabled?: (currentStepIndex: number) => boolean;
  steps?: StepperStep[];
};

export type StepperProps = {
  steps: (StepperStep | StepperStepWithSubSteps)[];
  boxProps?: BoxProps<'div'>;
  defaultStepIndex?: number;
  getStepperHeaderLabel?: (current: number, total: number) => string;
  onChange?: (step: number, subStep: number) => void;
};

export type NavigateTo = (index: number) => void;

export type NavigateToSubStep = (subStepIndex: number) => void;

export const Stepper = ({
  steps,
  defaultStepIndex = 0,
  getStepperHeaderLabel,
  onChange,
  boxProps,
}: StepperProps) => {
  const [currentStepIndex, setCurrentStepIndex] =
    React.useState(defaultStepIndex);
  const [currentSubStepIndex, setCurrentSubStepIndex] = React.useState(0);
  const currentStep = steps[currentStepIndex];
  const currentSubStep = currentStep?.steps?.[currentSubStepIndex];
  const navigateTo: NavigateTo = (index) => {
    setCurrentStepIndex(index);
    setCurrentSubStepIndex(0);
    onChange?.(index, 0);
  };

  const navigateToSubStep: NavigateToSubStep = (subStepIndex) => {
    setCurrentSubStepIndex(subStepIndex);
    onChange?.(currentStepIndex, subStepIndex);
  };

  return (
    <Box flexDirection="column" gap="s6" {...boxProps}>
      <StepperHeader
        steps={steps}
        navigateTo={navigateTo}
        currentStepIndex={currentStepIndex}
        currentSubStepIndex={currentSubStepIndex}
        getStepperHeaderLabel={getStepperHeaderLabel}
      />
      {currentStep?.Render ? (
        <currentStep.Render
          navigateTo={navigateTo}
          navigateToSubStep={navigateToSubStep}
          currentStepIndex={currentStepIndex}
          currentSubStepIndex={currentSubStepIndex}
        />
      ) : currentSubStep?.Render ? (
        <currentSubStep.Render
          navigateTo={navigateTo}
          navigateToSubStep={navigateToSubStep}
          currentStepIndex={currentStepIndex}
          currentSubStepIndex={currentSubStepIndex}
        />
      ) : null}
    </Box>
  );
};

export const STEPPER_HEADER_MAX_WIDTH = 256;

export type StepperHeaderProps = Pick<
  StepperProps,
  'steps' | 'getStepperHeaderLabel'
> & {
  currentStepIndex: number;
  currentSubStepIndex: number;
  navigateTo: NavigateTo;
};

export const StepperHeader = ({
  steps,
  navigateTo,
  currentStepIndex,
  currentSubStepIndex,
  getStepperHeaderLabel = (current, total) => `${current} / ${total}`,
}: StepperHeaderProps) => {
  const currentStep = steps[currentStepIndex];
  const currentStepSubSteps = currentStep?.steps;
  const stepCount = steps.length;
  const subStepCount = currentStepSubSteps?.length;
  const subStepCompletionPercentage =
    subStepCount && (currentSubStepIndex + 1) / subStepCount;

  return (
    <Box flexDirection="column" gap="s3">
      <Box flexDirection="column" gap="s1">
        <Typography variant="labelTag" color="tertiary">
          {getStepperHeaderLabel(currentStepIndex + 1, stepCount)}
        </Typography>
        <Typography variant="bodyMedium">{currentStep?.title}</Typography>
      </Box>
      <Box gap="s3" style={{ maxWidth: STEPPER_HEADER_MAX_WIDTH }}>
        {steps.map((step, index) => {
          const isPrevious = index < currentStepIndex;
          const isCurrent = index === currentStepIndex;
          const isCurrentOrPrevious = isPrevious || isCurrent;

          const isActive = isCurrentOrPrevious;
          const isActiveAndClickable =
            isCurrentOrPrevious && !step.disabled?.(currentStepIndex);

          return (
            <StepButton
              key={step.id}
              active={isActive}
              percentage={
                isPrevious
                  ? 1
                  : isCurrent
                  ? subStepCompletionPercentage ?? 1
                  : 0
              }
              disabled={!isActiveAndClickable}
              onClick={
                isActiveAndClickable
                  ? () => {
                      navigateTo(index);
                    }
                  : undefined
              }
            >
              {step.title}
            </StepButton>
          );
        })}
      </Box>
    </Box>
  );
};

export type StepButtonProps = StepperVariant &
  Omit<React.ComponentPropsWithRef<'button'>, 'color'> & {
    percentage: number;
  };

export const StepButton = ({
  active,
  percentage,
  style,
  ...props
}: StepButtonProps) => {
  const variables = {
    [`${cssVariable(stepCompletionPercentage)}`]: `${percentage * 100}%`,
  } as React.CSSProperties;
  return (
    <Box
      flex={1}
      as={Button}
      type="button"
      borderRadius="full"
      className={stepButtonStyles({ active })}
      style={{ ...variables, ...style }}
      {...props}
    />
  );
};
