import classNames from 'classnames';
import * as React from 'react';

import { useCombinedRefs } from '../../hooks/useCombinedRefs';
import { Box } from '../Box';
import { Icon } from '../Icon';
import { IconButton } from '../IconButton';
import { Tag } from '../Tag';
import { Typography } from '../Typography';

import { documentUploaderStyles } from './DocumentUploader.css';

type DocumentUploaderFileStatus =
  | 'uploaded'
  | 'in_review'
  | 'approved'
  | 'rejected';

export type DocumentUploaderProps = Pick<
  React.ComponentProps<'input'>,
  'accept'
> & {
  onRemoveFile: (fileIndex: number) => void;
  title: string;
  description?: string;
  files?: {
    name: string;
    status: DocumentUploaderFileStatus;
  }[];
  onAddFiles?: (files: File[]) => void;
};

export const DocumentUploader = React.forwardRef<
  HTMLInputElement,
  DocumentUploaderProps
>(
  (
    { title, description, files = [], accept, onAddFiles, onRemoveFile },
    ref
  ) => {
    const id = React.useId();
    const containerRef = React.useRef<HTMLLabelElement>(null);
    const inputRef = React.useRef<HTMLInputElement>(null);

    const combinedRef = useCombinedRefs(ref, inputRef);

    const addActiveClass = () =>
      containerRef.current?.classList.add(
        documentUploaderStyles.containerDragOver
      );

    const removeActiveClass = () =>
      containerRef.current?.classList.remove(
        documentUploaderStyles.containerDragOver
      );

    const onDragOver: React.DragEventHandler<HTMLLabelElement> = (event) => {
      event.preventDefault();
      addActiveClass();
    };

    const handleLabelDrop: React.DragEventHandler<HTMLLabelElement> = (
      event
    ) => {
      event.preventDefault();
      onAddFiles?.(Array.from(event.dataTransfer.files));
      removeActiveClass();
    };

    const getTagFromStatus = (status: DocumentUploaderFileStatus) => {
      switch (status) {
        case 'in_review':
          return <Tag size="small">{status}</Tag>;
        case 'approved':
          return (
            <Tag size="small" variant="success">
              {status}
            </Tag>
          );
        case 'rejected':
          return (
            <Tag size="small" variant="error">
              {status}
            </Tag>
          );
      }
    };

    // used to remove active class when the user cancels the file upload
    React.useEffect(() => {
      const inputRefCurrent = inputRef.current;

      inputRefCurrent?.addEventListener('cancel', removeActiveClass);

      return () => {
        inputRefCurrent?.removeEventListener('cancel', removeActiveClass);
      };
    }, []);

    return (
      <Box
        tabIndex={0}
        as="label"
        draggable
        htmlFor={id}
        ref={containerRef}
        className={classNames(documentUploaderStyles.container)}
        onDragLeave={removeActiveClass}
        onDragOver={onDragOver}
        onDrop={handleLabelDrop}
        onKeyDown={(event: React.KeyboardEvent) => {
          if (event.key === ' ' || event.key === 'Enter') {
            event.preventDefault();
            inputRef.current?.click();
          }
        }}
        onClick={addActiveClass}
        flexDirection="column"
        gap="s5"
        paddingHorizontal={{ mobile: 's5', tablet: 's6' }}
        paddingVertical="s5"
      >
        <Box flex={1} alignItems="center">
          <Box backgroundColor="tertiary" borderRadius="full" padding="s3">
            <Icon name="file" size="small" />
          </Box>
          <Box flex={1} flexDirection="column" marginLeft="s5" marginRight="s7">
            <Typography variant="bodyLarge">{title}</Typography>
            {description && (
              <Typography variant="bodyMedium" color="tertiary">
                {description}
              </Typography>
            )}
          </Box>
          <IconButton
            variant="tertiary"
            size="small"
            icon="arrowUpFromBracket"
          />
        </Box>
        {files.length > 0 ? (
          <Box flexDirection="column" gap="s3">
            {files.map(({ name, status }, index) => (
              <Box
                key={name}
                flexDirection="row"
                gap="s3"
                justifyContent="space-between"
              >
                <Typography variant="bodyMedium" color="secondary">
                  {name}
                </Typography>
                {status === 'uploaded' ? (
                  <Box<'button'>
                    as="button"
                    onClick={(event) => {
                      event.preventDefault();
                      event.stopPropagation();
                      onRemoveFile(index);
                    }}
                  >
                    <Icon name="close" size="medium" color="primary" />
                  </Box>
                ) : (
                  getTagFromStatus(status)
                )}
              </Box>
            ))}
          </Box>
        ) : null}
        <input
          id={id}
          type="file"
          tabIndex={-1}
          accept={accept}
          ref={combinedRef}
          className={documentUploaderStyles.input}
          onChange={(event) => {
            event.preventDefault();
            onAddFiles?.(Array.from(event.target.files ?? []));
          }}
        />
      </Box>
    );
  }
);

DocumentUploader.displayName = 'DocumentUploader';
