import classNames from 'classnames';
import * as React from 'react';

import { Box } from '../Box';
import { Icon, IconName } from '../Icon';
import {
  PolymorphicComponentPropWithRef,
  PolymorphicRef,
} from '../Polymorphic';
import { Typography, TypographyVariants } from '../Typography';

import { chipStyles, ChipVariants } from './Chip.css';

export type ChipProps = ChipVariants &
  React.PropsWithChildren<{
    prefixIcon?: IconName;
    suffixIcon?: IconName;
  }>;

export type ChipPolymorphicProps<C extends React.ElementType> =
  PolymorphicComponentPropWithRef<C, ChipProps>;

export type ChipComponent = <C extends React.ElementType = 'button'>(
  props: ChipPolymorphicProps<C>
) => React.ReactNode | null;

export const Chip: ChipComponent & { displayName?: string } = React.forwardRef(
  <C extends React.ElementType = 'button'>(
    {
      as,
      active,
      size = 'medium',
      variant = 'primary',
      prefixIcon,
      suffixIcon,
      children,
      className,
      ...componentProps
    }: ChipPolymorphicProps<C>,
    ref?: PolymorphicRef<C>
  ) => {
    const Component = as || 'button';

    const MAP_SIZE_TO_TEXT_VARIANT: {
      [key in typeof size]: TypographyVariants;
    } = {
      xsmall: 'labelCaption',
      small: 'labelXSmall',
      medium: 'labelSmall',
    };

    const childrenWithOptionalGradient =
      variant === 'gradient' ? (
        <Typography variant="gradient">{children}</Typography>
      ) : (
        children
      );

    return (
      <Component
        ref={ref}
        className={classNames(chipStyles({ active, size, variant }), className)}
        as="button"
        type="button"
        {...componentProps}
      >
        <Box
          alignItems="center"
          justifyContent="center"
          gap="s3"
          style={{
            opacity:
              variant === 'gradient' && componentProps.disabled ? 0.2 : 1,
          }}
        >
          {prefixIcon ? <Icon name={prefixIcon} size={size} /> : null}
          <Typography variant={MAP_SIZE_TO_TEXT_VARIANT[size]}>
            {childrenWithOptionalGradient}
          </Typography>
          {suffixIcon ? <Icon name={suffixIcon} size={size} /> : null}
        </Box>
      </Component>
    );
  }
);

Chip.displayName = 'Button';
