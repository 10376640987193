import { Box } from '../Box';
import { Icon, IconProps } from '../Icon';
import { Tooltip, TooltipProps } from '../Tooltip';

import { infoCircleTooltipStyles } from './InfoCircleTooltip.css';

export type InfoCircleTooltipProps = Omit<TooltipProps, 'children'> &
  Pick<IconProps, 'color'> & {
    iconSize?: IconProps['size'];
  };

export const InfoCircleTooltip = ({
  size = 'small',
  iconSize = 'medium',
  color,
  ...props
}: InfoCircleTooltipProps) => {
  return (
    <Tooltip size={size} {...props}>
      <Box as="button" type="button" className={infoCircleTooltipStyles.button}>
        <Icon name="infoCircle" size={iconSize} color={color} />
      </Box>
    </Tooltip>
  );
};
